<template>
  <div style="background-color: #ebedf0">
    <MyHeader :title="$t('lang.menu_temppatroldetail')" :ShowBack="true" />
    <div class="repair-panel">
      <div>{{ $t("lang.time") }}：{{ mData.arriveTime }}</div>
      <div>{{ $t("lang.department") }}：{{ mData.deptName }}</div>
      <div>{{ $t("lang.inspector") }}：{{ mData.inspectorName }}</div>
      <div>{{ $t("lang.descriptions") }}：{{ mData.comment }}</div>
    </div>

    <div class="repair-panel">
      <div
        class="repair-radio"
        v-show="mImage.length > 0 && mImage[0] != ''"
        v-for="m in mImage"
        :key="m"
      >
        <van-image width="62" height="62" :src="m" @click="onShowImg(0)" />
      </div>
      <div class="repair-radio" v-show="mData.recordUrl != ''">
        <van-icon
          name="music"
          size="40"
          style="margin-top: 10px"
          @click="onRecord()"
        />
      </div>
      <div class="repair-radio" v-show="mData.videoUrl != ''">
        <van-icon
          name="play-circle"
          size="40"
          style="margin-top: 10px"
          @click="onVideo()"
        />
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="repair-panel" id="container"></div>

    <van-dialog
      v-model="showVideo"
      show-cancel-button
      :show-confirm-button="false"
      :cancel-button-text="$t('lang.close')"
      @close="onClose()"
    >
      <video-player
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
      >
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.repair-panel {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #646566;
  margin-top: 5px;
  background-color: #fff;
}
.van-image {
  margin-right: 5px;
}
.repair-radio {
  height: 62px;
  width: 62px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background-color: #eee;
  float: left;
  text-align: center;
}
#container {
  width: 100%;
  height: 400px;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import AMapLoader from "@amap/amap-jsapi-loader";
import MyHeader from "@/components/Header.vue";
import { GetServerUrl } from "@/api/index.js";

export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      mapObj: null,
      icon0: null,
      textStyle: null,

      mData: {},
      mImage: [],
      mImage1: [],

      ServerUrl: "",

      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.data) {
      this.mData = this.$route.params.data;
      this.ServerUrl = GetServerUrl();
      let imgurl = this.mData.imageUrl.replace(
        RegExp("/app/images", "g"),
        this.ServerUrl + "app/images"
      );
      this.mImage = imgurl.split("|");
      this.mImage1 = imgurl.replace(RegExp("preview/", "g"), "").split("|");

      if (this.mData.lng != 0 && this.mData.lat != 0) {
        let that = this;
        AMapLoader.load({
          key: "a69152cfe2fdabeb495c2e17e3e930b2",
          version: "1.4.15",
          plugins: ["AMap.MapType"],
        })
          .then((AMap) => {
            that.mapObj = new AMap.Map("container", {
              resizeEnable: true,
              zoom: 17,
              center: [that.mData.lng, that.mData.lat],
            });

            that.mapObj.addControl(
              new AMap.MapType({
                defaultType: 0, //0代表默认，1代表卫星
              })
            );

            AMap.plugin("AMap.ToolBar", function () {
              let toolbar = new AMap.ToolBar();
              that.mapObj.addControl(toolbar);
            });

            let marker = new AMap.Marker({
              position: [that.mData.lng, that.mData.lat],
            });
            marker.setMap(that.mapObj);

            // 将 marker 添加到图层
            let labelsLayer = new AMap.LabelsLayer({
              zooms: [5, 20],
              zIndex: 1000,
              collision: false,
            });
            this.mapObj.add(labelsLayer);
            this.mapObj.setFitView();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  },

  methods: {
    onShowImg(i) {
      ImagePreview({
        images: this.mImage1,
        startPosition: i,
      });
    },

    onRecord() {
      this.playerOptions.sources[0].src = this.ServerUrl + this.mData.recordUrl;
      this.showVideo = true;
    },

    onVideo() {
      this.playerOptions.sources[0].src = this.ServerUrl + this.mData.videoUrl;
      this.showVideo = true;
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },
  },
};
</script>
